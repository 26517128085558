import { useEffect } from 'react';
import LogoLight from '../assets/images/its logo.png';

const MainLoader = ({useInfo}) => {
    // useEffect(()=>{
        

    // },[])
    return (
        <>
       
            <div className="" id="status" >
             <span className="logo-lg">
          {useInfo&& <img src={useInfo?.logopath} alt="" height="90" width="110" />}
        </span>
                {/* <div className="spinner" style={{width:'92px',height:'92px', position: "absolute",top: '0px',left:'0'}}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div> */}
            </div>
      </>
    );
};

export default MainLoader;
// import { useEffect, useState } from 'react';
// import "../App.css";

// const MainLoader = ({ useInfo }) => {
//     return (
//         <div className="status main-loader">
//                 <span className="logo-lg">
//                     {useInfo && <img src={useInfo?.logopath} alt="" height="90" width="110" />}
//                 </span>
            
//         </div>
//     );
// };

// export default MainLoader;

