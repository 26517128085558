import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function addEmp(params: { cardno:string;empname:string;fname:string;dateofbirth:string;gender:string;cnic:string;mainaddress:string;cityid:string;martialStatus:string;religionid:string;phone:string;alterPhone:string;email:string;contactperson:string;phone2:string;address:string;joindate:string;jobtypeid:string;departmentid:string;designationid:string;salary:string;cleave:string;sickleave:string;annualleave:string;startdate:string;enddate:string;description:string;incrementPer:string;inctype:string;workingdays:string;timefrom:string;timeto:string;codeofconduct:string;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object}) {
  console.log('employee add---');
    const baseUrl = '/save-employee';
    return api.create(`${baseUrl}`, params);
}
function addDes_API(params: {name:string;}) {
    const baseUrl = '/save-designation';
    return api.create(`${baseUrl}`, params);
}
function addDep_API(params: {name:string;}) {
    const baseUrl = '/save-department';
    return api.create(`${baseUrl}`, params);
}
function addJobtype_API(params: {name:string;}) {
    const baseUrl = '/save-jobtype';
    return api.create(`${baseUrl}`, params);
}
function addAsset_API(params: {name:string;}) {
    const baseUrl = '/save-assettype';
    return api.create(`${baseUrl}`, params);
}
function addBonus_API(params: {name:string;}) {
    const baseUrl = '/save-bonustype';
    return api.create(`${baseUrl}`, params);
}
function addDoctype_API(params: {name:string;}) {
    const baseUrl = '/save-documenttype';
    return api.create(`${baseUrl}`, params);
}
axios.interceptors.response.use(
  (response) => {
      return response;
  },
);
export {addEmp,addDes_API,addAsset_API,
addBonus_API,
addDoctype_API,
addDep_API,
addJobtype_API};
