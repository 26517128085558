
import { AdminActionTypes } from './constants';



const INIT_STATE = {
    user:null,
    updateUser:null,
    roles_response:null,
    dashbaord_rights:null,
    cashcounter_rights:null,
    loginPage:null,
    finalYear:null,
    loading:false,
    error:null,
};

const Admin =(state=INIT_STATE , action)=>{
    switch(action.type){
        case AdminActionTypes.API_RESPONSE_SUCCESS :
            switch(action.payload.actionType){
                case AdminActionTypes.USER_MANAGEMENT :
                    return {
                    ...state,
                    user:action.payload,
                }
                case AdminActionTypes.FINANCIAL_YEAR :
                    return {
                    ...state,
                    finalYear:action.payload,
                }
                case AdminActionTypes.DASHBOARD_RIGHTS :
                    return {
                    ...state,
                    dashbaord_rights:action.payload,
                }
                case AdminActionTypes.CASHCOUNTER_RIGHTS :
                    return {
                    ...state,
                    cashcounter_rights:action.payload,
                }
                case AdminActionTypes.ROLE :
                    return {
                    ...state,
                    roles_response:action.payload,
                }
                case AdminActionTypes.USER_UPDATE :
                    return {
                    ...state,
                    updateUser:action.payload,
                }
                case AdminActionTypes.MANAGE_LOGIN :
                    return {
                    ...state,
                    loginPage:action.payload,
                }
                default: return { ...state };    
            }
        case AdminActionTypes.API_RESPONSE_ERROR :
            switch(action.payload.actionType){
                case AdminActionTypes.USER_MANAGEMENT :
                    return {
                    ...state,
                    error:action.payload.error,
                    user:null,
                    loading:true,
                }
                case AdminActionTypes.FINANCIAL_YEAR :
                    return {
                    ...state,
                    error:action.payload.error,
                    finalYear:null,
                    loading:true,
                }
                case AdminActionTypes.DASHBOARD_RIGHTS :
                    return {
                    ...state,
                    error:action.payload.error,
                    dashbaord_rights:null,
                    loading:true,
                }
                case AdminActionTypes.CASHCOUNTER_RIGHTS :
                    return {
                    ...state,
                    error:action.payload.error,
                    cashcounter_rights:null,
                    loading:true,
                }
                case AdminActionTypes.ROLE :
                    return {
                    ...state,
                    error:action.payload.error,
                    roles_response:null,
                    loading:true,
                }
                case AdminActionTypes.USER_UPDATE :
                    return {
                    ...state,
                    error:action.payload.error,
                    updateUser:null,
                    loading:true,
                }
                case AdminActionTypes.MANAGE_LOGIN :
                    return {
                    ...state,
                    error:action.payload.error,
                    loginPage:null,
                    loading:true,
                }
               default: return { ...state };     
            }
            
    case AdminActionTypes.FINANCIAL_YEAR: return { ...state, loading: true, finalYear: null };
    case AdminActionTypes.DASHBOARD_RIGHTS: return { ...state, loading: true, dashbaord_rights: null };
    case AdminActionTypes.CASHCOUNTER_RIGHTS: return { ...state, loading: true, cashcounter_rights: null };
    case AdminActionTypes.USER_MANAGEMENT: return { ...state, loading: true, user: null };
    case AdminActionTypes.USER_UPDATE: return { ...state, loading: true, updateUser: null };
    case AdminActionTypes.ROLE: return { ...state, loading: true, roles_response: null };
    case AdminActionTypes.MANAGE_LOGIN: return { ...state, loading: true, loginPage: null };
    case AdminActionTypes.RESET: return {...state,loading:false,roles_response: null,user:null,updateUser:null,finalYear:null,error:false,dashbaord_rights:null,cashcounter_rights:null,loginPage:null}
       default:return { ...state };         
    }
}

export default Admin;
