import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// helpers
import {
    addEmp as addEmploy,addDes_API,addDep_API,addAsset_API,
addBonus_API,
addDoctype_API,addJobtype_API} from '../../helpers';

// actions
import { employeeApiResponseSuccess, employeeApiResponseError } from './actions';

// constants
import { EmployeeActionTypes } from './constants';

type EmpData = {
    payload: {
       cardno:string;empname:string;fname:string;dateofbirth:string;gender:string;cnic:string;mainaddress:string;cityid:string;martialStatus:string;religionid:string;phone:string;alterPhone:string;email:string;contactperson:string;phone2:string;address:string;joindate:string;jobtypeid:string;departmentid:string;designationid:string;salary:string;cleave:string;sickleave:string;annualleave:string;startdate:string;enddate:string;description:string;incrementPer:string;inctype:string;workingdays:string;timefrom:string;timeto:string;codeofconduct:string;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object
    };
    type: string;
};
type modalData = {
    payload: {name:string;};
    type: string;
};

const api = new APICore();
// const [getRes , setRes] = useState()
export const setResponse = {
    newResponse:null
}


function* add({ payload: {cardno,empname,fname,dateofbirth,gender,cnic,mainaddress,cityid,martialStatus,religionid,phone,alterPhone,email,contactperson,phone2,address,joindate,jobtypeid,departmentid,designationid,salary,cleave,sickleave,annualleave,startdate,enddate,description,incrementPer,inctype,workingdays,timefrom,timeto,codeofconduct,bonusgrid,assetsgrid,backgroundgrid,documentgrid} }: EmpData): SagaIterator {
    try {
        const response = yield call(addEmploy, {cardno,empname,fname,dateofbirth,gender,cnic,mainaddress,cityid,martialStatus,religionid,phone,alterPhone,email,contactperson,phone2,address,joindate,jobtypeid,departmentid,designationid,salary,cleave,sickleave,annualleave,startdate,enddate,description,incrementPer,inctype,workingdays,timefrom,timeto,codeofconduct,bonusgrid,assetsgrid,backgroundgrid,documentgrid,});
        console.log(response);
        setResponse['newResponse'] = response;
        const item = response.data;
        // api.setLoggedInItem(item);
        // setItmmngtorization(item['token']);
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_EM, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_EM, error));
            }
}
function* addDesig({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addDes_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_DESIG, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_DESIG, error));
            }
}
function* addDep({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addDep_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_DEPART, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_DEPART, error));
            }
}
function* addJobType({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addJobtype_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_JOBTYPE, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_JOBTYPE, error));
            }
}
function* addAsset({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addAsset_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_ASSET, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_ASSET, error));
            }
}
function* addBonus({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addBonus_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_BONUS, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_BONUS, error));
            }
}
function* addDoc({ payload: {name} }: modalData): SagaIterator {
    try {
        const response = yield call(addDoctype_API, {name});
        const item = response.data;
        yield put(employeeApiResponseSuccess(EmployeeActionTypes.ADD_DOC, item));
    } catch (error: any) {
        yield put(employeeApiResponseError(EmployeeActionTypes.ADD_DOC, error));
            }
}


export function* watchAdd(): any {
    yield takeEvery(EmployeeActionTypes.ADD_EM, add);
}
export function* watchDes(): any {
    yield takeEvery(EmployeeActionTypes.ADD_DESIG, addDesig);
}
export function* watchDep(): any {
    yield takeEvery(EmployeeActionTypes.ADD_DEPART, addDep);
}
export function* watchJobtype(): any {
    yield takeEvery(EmployeeActionTypes.ADD_JOBTYPE, addJobType);
}
export function* watchAsset(): any {
    yield takeEvery(EmployeeActionTypes.ADD_ASSET, addAsset);
}
export function* watchBonus(): any {
    yield takeEvery(EmployeeActionTypes.ADD_BONUS, addBonus);
}
export function* watchDoctype(): any {
    yield takeEvery(EmployeeActionTypes.ADD_DOC, addDoc);
}


function* employeeSaga() {
    yield all([fork(watchAdd)]);
    yield all([fork(watchDes)]);
    yield all([fork(watchDep)]);
    yield all([fork(watchJobtype)]);
    yield all([fork(watchAsset)]);
    yield all([fork(watchBonus)]);
    yield all([fork(watchDoctype)]);
}

export default employeeSaga;
