// constants
import { EmployeeActionTypes } from './constants';

export type EmployeeActionType = {
    type:
        | EmployeeActionTypes.API_RESPONSE_SUCCESS
        | EmployeeActionTypes.API_RESPONSE_ERROR
        | EmployeeActionTypes.RESET
        | EmployeeActionTypes.ADD_EM
        | EmployeeActionTypes.ADD_DESIG
        | EmployeeActionTypes.ADD_DEPART
        | EmployeeActionTypes.ADD_JOBTYPE
        | EmployeeActionTypes.ADD_ASSET
        | EmployeeActionTypes.ADD_BONUS
        | EmployeeActionTypes.ADD_DOC
    payload: {} | string;
};

type EmpData = {
     cardno:string;empname:string;fname:string;dateofbirth:string;gender:string;cnic:string;mainaddress:string;cityid:string;martialStatus:string;religionid:string;phone:string;alterPhone:string;email:string;contactperson:string;phone2:string;address:string;joindate:string;jobtypeid:string;departmentid:string;designationid:string;salary:string;cleave:string;sickleave:string;annualleave:string;startdate:string;enddate:string;description:string;incrementPer:string;inctype:string;workingdays:string;timefrom:string;timeto:string;codeofconduct:string;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object
};

// common success
export const employeeApiResponseSuccess = (actionType: string, data: EmpData | {}): EmployeeActionType => ({
    type: EmployeeActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const employeeApiResponseError = (actionType: string, error: string): EmployeeActionType => ({
    type: EmployeeActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addEmp = (cardno:string,empname:string,fname:string,dateofbirth:string,gender:string,cnic:string,mainaddress:string,cityid:string,martialStatus:string,religionid:string,phone:string,alterPhone:string,email:string,contactperson:string,phone2:string,address:string,joindate:string,jobtypeid:string,departmentid:string,designationid:string,salary:string,cleave:string,sickleave:string,annualleave:string,startdate:string,enddate:string,description:string,incrementPer:string,inctype:string,workingdays:string,timefrom:string,timeto:string,codeofconduct:string,bonusgrid:object,assetsgrid:object,backgroundgrid:object,documentgrid:object): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_EM,
    payload: {cardno,empname,fname,dateofbirth,gender,cnic,mainaddress,cityid,martialStatus,religionid,phone,alterPhone,email,contactperson,phone2,address,joindate,jobtypeid,departmentid,designationid,salary,cleave,sickleave,annualleave,startdate,enddate,description,incrementPer,inctype,workingdays,timefrom,timeto,codeofconduct,bonusgrid,assetsgrid,backgroundgrid,documentgrid,},
});


export const addDesignation = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_DESIG,
    payload: {name},
});
export const addDepartment = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_DEPART,
    payload: {name},
});
export const addJobType = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_JOBTYPE,
    payload: {name},
});
export const addAssettype = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_ASSET,
    payload: {name},
});
export const addBonustype = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_BONUS,
    payload: {name},
});
export const addDoctype = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_DOC,
    payload: {name},
});
export const resetEmpolyee = (): EmployeeActionType => ({
    type: EmployeeActionTypes.RESET,
    payload: {},
});
