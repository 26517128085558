// apicore
import { APICore } from '../../helpers/api/apiCore';

// constants
import { EmployeeActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
    EmpAdded: null,
    addedDes: null,
    addedDepart: null,
    addedJobtype: null,
    addedAssettype: null,
    addedBonustype: null,
    addedDoctype: null,
    loading: false,
    modalLoading: false,
    error:null,
};

type EmpData = {
    cardno:string;empname:string;fname:string;dateofbirth:string;gender:string;cnic:string;mainaddress:string;cityid:string;martialStatus:string;religionid:string;phone:string;alterPhone:string;email:string;contactperson:string;phone2:string;address:string;joindate:string;jobtypeid:string;departmentid:string;designationid:string;salary:string;cleave:string;sickleave:string;annualleave:string;startdate:string;enddate:string;description:string;incrementPer:string;inctype:string;workingdays:string;timefrom:string;timeto:string;codeofconduct:string;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object
};


type EmployeeActionType = {
    type:
        | EmployeeActionTypes.API_RESPONSE_SUCCESS
        | EmployeeActionTypes.API_RESPONSE_ERROR
        | EmployeeActionTypes.ADD_EM
        | EmployeeActionTypes.ADD_DESIG
        | EmployeeActionTypes.ADD_DEPART
        | EmployeeActionTypes.ADD_JOBTYPE
        | EmployeeActionTypes.ADD_ASSET
        | EmployeeActionTypes.ADD_BONUS
        | EmployeeActionTypes.ADD_DOC
        | EmployeeActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: EmpData | null;
        loading: boolean;
        error?: string;
    };
};

type State = {
    EmpAdded?: EmpData | null;
    loading?: boolean;
    value?: boolean;
};

const Employee = (state: State = INIT_STATE, action: EmployeeActionType): any => {
    switch (action.type) {
        case EmployeeActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EmployeeActionTypes.ADD_EM: {
                    return {
                        ...state,
                        EmpAdded: action.payload,
                        loading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DESIG: {
                    return {
                        ...state,
                        addedDes: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DEPART: {
                    return {
                        ...state,
                        addedDepart: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_JOBTYPE: {
                    return {
                        ...state,
                        addedJobtype: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_ASSET: {
                    return {
                        ...state,
                        addedAssettype: action.payload,
                        modalLoading: false,
                    };
                }
            
                case EmployeeActionTypes.ADD_BONUS: {
                    return {
                        ...state,
                        addedBonustype: action.payload,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DOC: {
                    return {
                        ...state,
                        addedDoctype: action.payload,
                        modalLoading: false,
                    };
                }
                
                default:
                    return { ...state };
            }

        case EmployeeActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case EmployeeActionTypes.ADD_EM: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        EmpAdded: null,
                        loading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DESIG: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedDes: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DEPART: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedDepart: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_JOBTYPE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedJobtype: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_ASSET: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedAssettype: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_BONUS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedBonustype: null,
                        modalLoading: false,
                    };
                }
                case EmployeeActionTypes.ADD_DOC: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedDoctype: null,
                        modalLoading: false,
                    };
                }
                
                default:
                    return { ...state };
            }

        case EmployeeActionTypes.ADD_EM:
            return { ...state, loading: true, EmpAdded: null };
        case EmployeeActionTypes.ADD_DESIG:
            return { ...state, modalLoading: true, addedDes: null };
        case EmployeeActionTypes.ADD_DEPART:
            return { ...state, modalLoading: true, addedDepart: null };
        case EmployeeActionTypes.ADD_JOBTYPE:
            return { ...state, modalLoading: true, addedJobtype: null };
        case EmployeeActionTypes.ADD_ASSET:
            return { ...state, modalLoading: true, addedAssettype: null };
        case EmployeeActionTypes.ADD_BONUS:
            return { ...state, modalLoading: true, addedBonustype: null };
        case EmployeeActionTypes.ADD_DOC:
            return { ...state, modalLoading: true, addedDoctype: null };
        case EmployeeActionTypes.RESET:
            return {
                ...state,
                loading: false,
                modalLoading: false,
                error: null,
                EmpAdded: null,
                addedDes: null,
                addedDepart: null,
                addedJobtype: null,
                addedAssettype: null,
                addedBonustype: null,
                addedDoctype: null,
             };
        default:
            return { ...state };
    }
};

export default Employee;
