export enum EmployeeActionTypes {
    API_RESPONSE_SUCCESS = '@@employee/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@employee/API_RESPONSE_ERROR',
    ADD_EM = '@@employee/ADD_EM',
    ADD_DESIG = '@@employee/ADD_DESIG',
    ADD_DEPART = '@@employee/ADD_DEPART',
    ADD_JOBTYPE = '@@employee/ADD_JOBTYPE',
    ADD_ASSET = '@@employee/ADD_ASSET',
    ADD_BONUS = '@@employee/ADD_BONUS',
    ADD_DOC = '@@employee/ADD_DOC',
    RESET = '@@employee/RESET',
}