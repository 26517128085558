import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// helpers
import {
    addItem as addApi,
    updItem as updApi,
    addInvUnit as addiuApi,
    addItemBrand as addbrandApi,
    addItemCode as addcodeApi,
    addItemType as itemTypeApi,
    addItemCategory as addcategoryApi,
    addOpeningStock as addopeningstockApi,
    addStockAdjustment as addStockAdjustmentApi,
    addStockTransfer as addStockTransferApi,
    updateOpeningStocks as updOpeningStockApi,
    updateStockAdjustment as updateStockAdjustmentApi,
    updateStockTrans as updateStockTransApi,
    addStockIn as addStockInApi,
    updateStockIn as updateStockInApi,
    addStockOut as addStockOutApi,
    updateStockOut as updateStockOutApi,
    FormLocManage as formLoc,subCategoryApi,multipleItems_api,updmultipleItems_api
} from '../../helpers';

// actions
import { itmmngtApiResponseSuccess, itmmngtApiResponseError } from './actions';

// constants
import { ItmmngtActionTypes } from './constants';

type ItemData = {
    payload: {
        id:string;itemcodetype:string; itemcode:string; barcode:string; description: string; UrduDescription:string; itemtype:string; categoryid:string; brandid:string; masterpackingunitid:string;  unitprice:string; price1:string; itemcosteach:string; notes:string;minstocklevel:string;codetype:string;allowdis:string;subcatid:string;pcsperpack:string;packpermaster:string;unitweight:string;image:any;pcsperpackunitid:string;packpermasterunitid:string;negativestock:string;
    };
    type: string;
};
type UnitData = {
    payload: {
        unitname:string;  
    };
    type: string;
};
type OpeningStockData = {
    payload: {
        ITDate:string;  
        DetailedDescription:string;
        osdetdata:object;
    };
    type: string;
};
type UpdOpeningStockData = {
    payload: {
        id:string;
        ITDate:string;  
        DetailedDescription:string;
        osdetdata:object;
    };
    type: string;
};
type StockAdjustmentData = {
    payload: {
        ITDate:string;  
        DetailedDescription:string;
        adjdetdata:object;
    };
    type: string;
};
type StockTransData = {
    payload: {
    id:string;
        ITDate:string;  
        DetailedDescription:string;
        transdetdata:object;
    };
    type: string;
};
type StockAdjustmentDataUPD = {
    payload: {
        id:string;
        ITDate:string;  
        DetailedDescription:string;
        adjdetdata:object;
    };
    type: string;
};
type BrandData = {
    payload: {
        name:string;  
    };
    type: string;
};
type VarietyData = {
    payload: {
        name:string;  
    };
    type: string;
};
type CategoryData = {
    payload: {
        id:string;
        name:string;  
    };
    type: string;
};
type SubCategoryData = {
    payload: {
        catid:string;
        name:string;  
    };
    type: string;
};
type locData = {
    payload: {
        name: string;address: string;city: string;state: string;code: string;country: string;phone: string;phonedes: string;email: string;emaildes: string; phonecode:string;
    };
    type: string;
};
type mutliItemType = {
    payload: {data_array:object};
    type: object;
};
type mutliItemUPD = {
    payload: {data_array:object;id:object};
    type: object;
};
type itemCodeType = {
    payload: {
        itemcodetype:string;
        setcode:string;
        setnumber:string;  
        codetype:string;  
    };
    type: string;
};
type TypeData = {
    payload: {
        type:string;  
    };
    type: string;
};

const api = new APICore();

export const addResponse = {
    newResponse:null
}
export const upditemResponse = {
    newResponse:null
}
export const typeResponse = {
    newResponse:null
}
export const catResponse = {
    newResponse:null
}
export const unitResponse = {
    newResponse:null
}
export const brandResponse = {
    newResponse:null
}
export const codeResponse = {
    newResponse:null
}
export const openingStock = {
    newResponse:null
}
export const openingStockUpd = {
    newResponse:null
}


function* add({ payload: {itemcodetype, itemcode,barcode, description, UrduDescription, itemtype, categoryid, brandid, masterpackingunitid, unitprice, price1, itemcosteach, notes,minstocklevel,codetype,allowdis,subcatid,pcsperpack,packpermaster,unitweight,pcsperpackunitid,packpermasterunitid,negativestock } }: ItemData): SagaIterator {
    try {
        const response = yield call(addApi, {itemcodetype,itemcode,barcode, description, UrduDescription, itemtype, categoryid, brandid, masterpackingunitid, unitprice, price1, itemcosteach, notes,minstocklevel,codetype,allowdis,subcatid,pcsperpack,packpermaster,unitweight,pcsperpackunitid,packpermasterunitid,negativestock, });
        console.log('saga');
        console.log(response);
        addResponse['newResponse'] = response;
        const item = response.data;
        yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_ITEM, item));
    } catch (error: any) {
        
        yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_ITEM, error));
    }
}

    function* addinvunit({ payload: { unitname } }: UnitData): SagaIterator {
    try {
        const response = yield call(addiuApi, { unitname });
        console.log(response);
          unitResponse['newResponse'] = response;
        const item = response.data;
        // api.setLoggedInItem(item);
        // setItmmngtorization(item['token']);
        yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_ITEMUNIT, item));
    } catch (error: any) {
        yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_ITEMUNIT, error));
            }
}
    function* additembrand({ payload: { name } }: BrandData): SagaIterator {
    try {
        const response = yield call(addbrandApi, { name });
        console.log(response);
          brandResponse['newResponse'] = response;
        const item = response.data;
        // api.setLoggedInItem(item);
        // setItmmngtorization(item['token']);
        yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_ITEMBRAND, item));
    } catch (error: any) {
        yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_ITEMBRAND, error));
            }
    }
    
    function* additemcode({ payload: {itemcodetype, setcode,setnumber,codetype } }:itemCodeType): SagaIterator 
    {
        try {
            const response = yield call(addcodeApi, {itemcodetype, setcode,setnumber,codetype });
            console.log(response);
            codeResponse['newResponse'] = response;
            const item = response.data;
           
            yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_ITEMCODE, item));
        } 
            catch (error: any) 
        {
            yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_ITEMCODE, error));
        }
    }
    
    function* addItemType({ payload: { type } }: TypeData): SagaIterator {
        try {
            const response = yield call(itemTypeApi, { type });
            console.log(response);
            const item = response.data;
              typeResponse['newResponse'] = response;
            // api.setLoggedInItem(item);
            // setItmmngtorization(item['token']);
            yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_ITEMTYPE, item));
        } catch (error: any) {
            yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_ITEMTYPE, error));
                }
        }
        function* additemcategory({ payload: { name } }: CategoryData): SagaIterator {
            try {
                const response = yield call(addcategoryApi, { name });
                console.log(response);
                const item = response.data;
                  catResponse['newResponse'] = response;
                // api.setLoggedInItem(item);
                // setItmmngtorization(item['token']);
                yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_ITEMCAT, item));
            } catch (error: any) {
                yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_ITEMCAT, error));
                    }
            }
        function* additemSubcategory({ payload: {catid, name } }: SubCategoryData): SagaIterator {
            try {
                const response = yield call(subCategoryApi, {catid, name });
                const item = response.data;
                yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_ITEMSUBCAT, item));
            } catch (error: any) {
                yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_ITEMSUBCAT, error));
                    }
            }
            function* addopeningstock({ payload: { ITDate, DetailedDescription, osdetdata } }: OpeningStockData): SagaIterator {
                try {
                    const response = yield call(addopeningstockApi, { ITDate, DetailedDescription, osdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStock['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_OS, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_OS, error));
                        }
                }
            function* updopeningstock({ payload: {id, ITDate, DetailedDescription, osdetdata } }: UpdOpeningStockData): SagaIterator {
                try {
                    const response = yield call(updOpeningStockApi, {id, ITDate, DetailedDescription, osdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStockUpd['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.UPD_OS, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.UPD_OS, error));
                        }
                }
            
            function* addStockAdjustment({ payload: { ITDate, DetailedDescription, adjdetdata } }: StockAdjustmentData): SagaIterator {
                try {
                    const response = yield call(addStockAdjustmentApi, { ITDate, DetailedDescription, adjdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStock['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_ADJUSTMENT, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_ADJUSTMENT, error));
                        }
                }
                
            function* updateStockAdjustment({ payload: {id, ITDate, DetailedDescription, adjdetdata } }: StockAdjustmentDataUPD): SagaIterator {
                try {
                    const response = yield call(updateStockAdjustmentApi, {id, ITDate, DetailedDescription, adjdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStockUpd['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.UPD_ADJUSTMENT, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.UPD_ADJUSTMENT, error));
                        }
                }
                
                
            function* addStockIn({ payload: { ITDate, DetailedDescription, adjdetdata } }: StockAdjustmentData): SagaIterator {
                try {
                    const response = yield call(addStockInApi, { ITDate, DetailedDescription, adjdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStock['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_STOCKIN, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_STOCKIN, error));
                        }
                }
                
            function* updateStockIn({ payload: {id, ITDate, DetailedDescription, adjdetdata } }: StockAdjustmentDataUPD): SagaIterator {
                try {
                    const response = yield call(updateStockInApi, {id, ITDate, DetailedDescription, adjdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStockUpd['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.UPD_STOCKIN, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.UPD_STOCKIN, error));
                        }
                }    

            function* addStockOut({ payload: { ITDate, DetailedDescription, adjdetdata } }: StockAdjustmentData): SagaIterator {
                try {
                    const response = yield call(addStockOutApi, { ITDate, DetailedDescription, adjdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStock['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_STOCKOUT, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_STOCKOUT, error));
                        }
                }
                
            function* updateStockOut({ payload: {id, ITDate, DetailedDescription, adjdetdata } }: StockAdjustmentDataUPD): SagaIterator {
                try {
                    const response = yield call(updateStockOutApi, {id, ITDate, DetailedDescription, adjdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStockUpd['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.UPD_STOCKOUT, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.UPD_STOCKOUT, error));
                        }
                }    

                
            function* addStockTrans({ payload: { ITDate, DetailedDescription, transdetdata } }: StockTransData): SagaIterator {
                try {
                    const response = yield call(addStockTransferApi, { ITDate, DetailedDescription, transdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStock['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.ADD_TRANSFER, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.ADD_TRANSFER, error));
                        }
                }
                
            function* updateStockTrans({ payload: {id, ITDate, DetailedDescription,transdetdata } }: StockTransData): SagaIterator {
                try {
                    const response = yield call(updateStockTransApi, {id, ITDate, DetailedDescription, transdetdata});
                    console.log(response);
                    const item = response.data;
                     openingStockUpd['newResponse'] = response;
                    yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.UPD_TRANSFER, item));
                } catch (error: any) {
                    yield put(itmmngtApiResponseError(ItmmngtActionTypes.UPD_TRANSFER, error));
                        }
                }
                
                
                
        function* manFormLoc({ payload: { name,address,city,state,code,country,phone,phonedes,email,emaildes,phonecode} }:locData): SagaIterator {
            try {
                const response = yield call(formLoc, { name,address,city,state,code,country,phone,phonedes,email,emaildes,phonecode});
                console.log(response);
                const item = response.data;
                yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.FORM_LOC, item));
            } catch (error: any) {
                yield put(itmmngtApiResponseError(ItmmngtActionTypes.FORM_LOC, error));
                    }
            }
        function* mutliItems({ payload: {data_array} }:mutliItemType): SagaIterator {
            try {
                const response = yield call(multipleItems_api, {data_array});
                const item = response.data;
                yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.MULTIPLE_ITEMS, item));
            } catch (error: any) {
                yield put(itmmngtApiResponseError(ItmmngtActionTypes.MULTIPLE_ITEMS, error));
                    }
            }
        function* updMultiple({ payload: {data_array,id} }:mutliItemUPD): SagaIterator {
            try {
                const response = yield call(updmultipleItems_api, {data_array,id});
                const item = response.data;
                yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.MULTIPLE_ITEMS_UPD, item));
            } catch (error: any) {
                yield put(itmmngtApiResponseError(ItmmngtActionTypes.MULTIPLE_ITEMS_UPD, error));
                    }
            }
            
                function* edit({ payload: {  id,  itemcodetype,itemcode,barcode, description, UrduDescription, itemtype, categoryid, brandid, masterpackingunitid, unitprice, price1, itemcosteach, notes,minstocklevel,codetype,allowdis,subcatid,pcsperpack,packpermaster,unitweight,pcsperpackunitid,packpermasterunitid,image,negativestock} }: ItemData): SagaIterator {
                    try {
                        const response = yield call(updApi, {  id, itemcodetype,itemcode,barcode, description, UrduDescription, itemtype, categoryid, brandid, masterpackingunitid, unitprice, price1, itemcosteach, notes,minstocklevel,codetype,allowdis,subcatid,pcsperpack,packpermaster,unitweight,pcsperpackunitid,packpermasterunitid,image,negativestock});
                        console.log(response);
                        upditemResponse['newResponse'] = response;
                        const item = response.data;
                        yield put(itmmngtApiResponseSuccess(ItmmngtActionTypes.EDIT_ITEM, item));
                    } catch (error: any) {
                        yield put(itmmngtApiResponseError(ItmmngtActionTypes.EDIT_ITEM, error));
                            }
                }



export function* watchAdd(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_ITEM, add);
}
export function* watchFormLoc(): any {
    yield takeEvery(ItmmngtActionTypes.FORM_LOC, manFormLoc);
}
export function* watchAddInvUnit(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_ITEMUNIT, addinvunit);
}
export function* watchAddItemBrand(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_ITEMBRAND, additembrand);
}

export function* watchAddItemCode(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_ITEMCODE, additemcode);
}

export function* watchAddItemType(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_ITEMTYPE, addItemType);
}
export function* watchAddItemCategory(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_ITEMCAT, additemcategory);
}
export function* watchAddSubItemCategory(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_ITEMSUBCAT, additemSubcategory);
}

export function* watchEdit(): any {
    yield takeEvery(ItmmngtActionTypes.EDIT_ITEM, edit);
}
export function* watchMutliItems(): any {
    yield takeEvery(ItmmngtActionTypes.MULTIPLE_ITEMS, mutliItems);
}

export function* watchAddOpeningStock(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_OS, addopeningstock);
}
export function* watchAddStockAdjustment(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_ADJUSTMENT, addStockAdjustment);
}
export function* watchUpdateStockAdjustment(): any {
    yield takeEvery(ItmmngtActionTypes.UPD_ADJUSTMENT, updateStockAdjustment);
}
export function* watchAddStockIn(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_STOCKIN, addStockIn);
}
export function* watchUpdateStockIn(): any {
    yield takeEvery(ItmmngtActionTypes.UPD_STOCKIN, updateStockIn);
}
export function* watchAddStockOut(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_STOCKOUT, addStockOut);
}
export function* watchUpdateStockOut(): any {
    yield takeEvery(ItmmngtActionTypes.UPD_STOCKOUT, updateStockOut);
}

export function* watchAddStockTrans(): any {
    yield takeEvery(ItmmngtActionTypes.ADD_TRANSFER, addStockTrans);
}
export function* watchUpdateStockTrans(): any {
    yield takeEvery(ItmmngtActionTypes.UPD_TRANSFER, updateStockTrans);
}
export function* watchUpdOpeningStock(): any {
    yield takeEvery(ItmmngtActionTypes.UPD_OS, updopeningstock);
}
export function* watchUpdMultiple(): any {
    yield takeEvery(ItmmngtActionTypes.MULTIPLE_ITEMS_UPD, updMultiple);
}


function* itmmngtSaga() {
    yield all([fork(watchAdd)]);
    yield all([fork(watchAddInvUnit)]);
    yield all([fork(watchEdit)]);
    yield all([fork(watchAddItemBrand)]);
     yield all([fork(watchAddItemCode)]);
    yield all([fork(watchAddItemType)]);
    yield all([fork(watchAddItemCategory)]);
    yield all([fork(watchAddSubItemCategory)]);
    yield all([fork(watchFormLoc)]);
    yield all([fork(watchMutliItems)]);
    yield all([fork(watchAddOpeningStock)]);
    yield all([fork(watchUpdOpeningStock)]);
    yield all([fork(watchUpdMultiple)]);
    yield all([fork(watchAddStockAdjustment)]);
    yield all([fork(watchUpdateStockAdjustment)]);
    yield all([fork(watchAddStockTrans)]);
    yield all([fork(watchUpdateStockTrans)]);
    yield all([fork(watchAddStockIn)]);
    yield all([fork(watchUpdateStockIn)]);
    yield all([fork(watchAddStockOut)]);
    yield all([fork(watchUpdateStockOut)]);
}

export default itmmngtSaga;
